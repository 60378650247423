import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken,getUser } from "../utils/index";
Vue.use(VueRouter)

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/',
		name: 'Home',
		redirect: '/dashboard',
	},
	{
		path: '/dashboard',
		name: '首页',
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
	},
	{
		path: '/layout',
		name: 'Layout',
		layout: "dashboard",
		component: () => import('../views/Layout.vue'),
	},
	{
		path: '/tables',
		name: '店铺',
		layout: "dashboard",
		component: () => import('../views/Tables.vue'),
	},
	{
		path: '/orders',
		name: '订单',
		layout: "dashboard",
		component: () => import('../views/Orders.vue'),
	},
	{
		path: '/orderdetail/:shop_id/:order_num', // 使用动态路径参数来接收订单号
		name: '订单详情',
		layout: "dashboard", // 指定布局
		component: () => import('../views/OrderDetail.vue'), // 指定组件
	},

	{
		path: '/accounts',
		name: '用户管理',
		layout: "dashboard",
		component: () => import('../views/Accounts.vue'),
	},
	{
		path: '/rtl',
		name: 'RTL',
		layout: "dashboard-rtl",
		meta: {
			layoutClass: 'dashboard-rtl',
		},
		component: () => import('../views/RTL.vue'),
	},
	{
		path: '/Profile',
		name: '个人信息',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
		},
		component: () => import('../views/Profile.vue'),
	},
	{
		path: '/sign-in',
		name: 'Sign-In',
		component: () => import('../views/Sign-In.vue'),
	},
	{
		path: '/sign-up',
		name: 'Sign-Up',
		meta: {
			layoutClass: 'layout-sign-up',
		},
		component: () => import('../views/Sign-Up.vue'),
	},
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
	route.meta = route.meta || {};
	route.meta.layout = route.layout || parentLayout;

	if (route.children) {
		route.children = route.children.map((childRoute) => addLayoutToRoute(childRoute, route.meta.layout));
	}
	return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
	const token = getToken(); // 获取 token
	const user = getUser();
	// 如果 token 不存在且不在登录页面，则跳转到登录页面
	if(to.path == '/accounts' && user != 'admin'){
		next('/404');
	}
	if (!token && to.path !== '/sign-in') {
		next('/sign-in');
	} else {
		next(); // 否则继续导航
	}
});

export default router
