import Cookies from 'js-cookie';
import moment from 'moment';

export function getToken() {
  return Cookies.get("token");
}

export function getUser() {
  return Cookies.get("type");
}

export function setToken(token) {
  Cookies.set("token", token, { expires: 1 }); // 设置有效期为1天（24小时）
}

export function setUser(user) {
  Cookies.set("type", user, { expires: 1 }); // 设置有效期为1天（24小时）
}

export function clearToken() {
  Cookies.remove("token");
}

export function dateFormat(dateStr) {
  return moment(dateStr).format("YYYY-MM-DD HH:mm");
}

export function setShopToken(tokenName, tokenValue) {
  console.log(tokenName, tokenValue)
  Cookies.set(tokenName, tokenValue, { expires: 1, domain: '.shopline.info' }); // 设置有效期为1天（24小时）
}
